<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-number"
      >
        {{item.transaction_id}}
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('trackForPayPal_courier.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.delivery_service_name}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('trackForPayPal_trackNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.tracking_number}}
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TrackingForPayPalMobile",

  props: {
    item: Object,
  },

  data() {
    return {
      show: false,
    }
  },
}
</script>

<style scoped>

</style>
