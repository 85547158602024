<template>
  <div class="fragment"></div>
<!--  <SearchEngine/>-->
</template>

<script>
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";

  export default {
    name: "TrackingPayPalHead",
    components:{
      // SearchEngine,
    },
  }
</script>

<style scoped>

</style>