var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"table-filter-wrap"},[(_vm.$store.getters.getTrackingForPayPal.length > 0 && _vm.$store.getters.getTrackingForPayPalLoading !== true)?[_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'trackForPayPal_transaction',
            'trackForPayPal_courier',
            'trackForPayPal_trackNumber',
            ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table mt-0"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('trackForPayPal_transaction.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('trackForPayPal_courier.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('trackForPayPal_trackNumber.localization_value.value')))])])]),_c('tbody',_vm._l((_vm.$store.getters.getTrackingForPayPal),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.transaction_id)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.delivery_service_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.tracking_number)+" ")])])}),0)]):_vm._e(),(_vm.isMobileFunc())?_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list"},_vm._l((_vm.$store.getters.getTrackingForPayPal),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('TrackingForPayPalMobile',{attrs:{"item":item}})],1)}),0)]):_vm._e()]),_c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"},[_c('div',{staticClass:"d-flex"},[(_vm.$store.getters.getTrackingForPayPalCommonList.next_page_url !== null && _vm.$store.getters.getTrackingForPayPal.length > 0)?_c('ShowMore',{class:{'disabled-btn' : _vm.$store.getters.getNextTrackingForPayPalPage},attrs:{"count":_vm.$store.getters.getTrackingForPayPalCommonList.total - _vm.$store.getters.getTrackingForPayPalForPage < _vm.$store.getters.getTrackingForPayPalForPage ?
                  _vm.$store.getters.getTrackingForPayPalCommonList.total - _vm.$store.getters.getTrackingForPayPalForPage:
                  _vm.$store.getters.getTrackingForPayPalForPage},nativeOn:{"click":function($event){return _vm.$emit('showMore')}}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('ExportBtn',{staticClass:"table-bottom-btn__right",on:{"downloadFiles":type => _vm.$emit('downloadFiles', type)}})],1)])])]:_vm._e(),(_vm.$store.getters.getTrackingForPayPalLoading === false && _vm.$store.getters.getTrackingForPayPal.length === 0)?[_c('NoResult',{attrs:{"countFilterParams":_vm.countFilterParams,"title":_vm.$t('common_noDataYet.localization_value.value'),"titleWithFilter":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noDataYetTxt.localization_value.value'),"textWithFilter":_vm.$t('common_noResultSeems.localization_value.value')},on:{"resetFilter":function($event){return _vm.$emit('resetFilter')}}})]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }