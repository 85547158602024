<template>
  <div class="fragment">
    <div class="table-filter-wrap">
      <template v-if="$store.getters.getTrackingForPayPal.length > 0 && $store.getters.getTrackingForPayPalLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'trackForPayPal_transaction',
            'trackForPayPal_courier',
            'trackForPayPal_trackNumber',
            ])"></div>
        <table class="site-table mt-0" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('trackForPayPal_transaction.localization_value.value')}}</th>
            <th>{{$t('trackForPayPal_courier.localization_value.value')}}</th>
            <th>{{$t('trackForPayPal_trackNumber.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in $store.getters.getTrackingForPayPal" :key="index">
            <td>
              {{item.transaction_id}}
            </td>
            <td>
              {{item.delivery_service_name}}
            </td>
            <td>
              {{item.tracking_number}}
            </td>
          </tr>
          </tbody>
        </table>


        <div class="table-card"  v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getTrackingForPayPal" :key="index"
            >
              <TrackingForPayPalMobile
                  :item="item"
              />
            </div>
          </div>
        </div>


      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <div class="d-flex">
            <ShowMore
                v-if="$store.getters.getTrackingForPayPalCommonList.next_page_url !== null && $store.getters.getTrackingForPayPal.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextTrackingForPayPalPage}"
                :count="$store.getters.getTrackingForPayPalCommonList.total - $store.getters.getTrackingForPayPalForPage < $store.getters.getTrackingForPayPalForPage ?
                  $store.getters.getTrackingForPayPalCommonList.total - $store.getters.getTrackingForPayPalForPage:
                  $store.getters.getTrackingForPayPalForPage"
            />
          </div>


          <div class="d-flex justify-content-end">
<!--            <TableLeftBlockBtn-->
<!--                :value="'Generate'"-->
<!--                :type="'generate'"-->
<!--            />-->
<!--            <TableLeftBlockBtn-->
<!--                :value="'Print'"-->
<!--                :type="'print'"-->
<!--            />-->
            <ExportBtn @downloadFiles="type => $emit('downloadFiles', type)" class="table-bottom-btn__right"/>
          </div>


        </div>
      </div>

      </template>

      <template v-if="$store.getters.getTrackingForPayPalLoading === false && $store.getters.getTrackingForPayPal.length === 0">
        <NoResult
            :countFilterParams="countFilterParams"
            :title="$t('common_noDataYet.localization_value.value')"
            :titleWithFilter="$t('common_noResult.localization_value.value')"
            :text="$t('common_noDataYetTxt.localization_value.value')"
            :textWithFilter="$t('common_noResultSeems.localization_value.value')"
            @resetFilter="$emit('resetFilter')"
        />
      </template>

    </div>
  </div>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TrackingForPayPalMobile from "./TrackingForPayPalMobile/TrackingForPayPalMobile";
  // import TableLeftBlockBtn from "../../../../../../coreComponents/TableComponents/TableLeftBlockBtn/TableLeftBlockBtn";

  export default {
    name: "TrackingPayPalTable",
    components: {
      TrackingForPayPalMobile,
      NoResult,
      ShowMore,
      ExportBtn,
      // TableLeftBlockBtn,
    },

    props: {
      countFilterParams: Number,
    },

    mixins: [mixinDetictingMobile],


    data() {
      return {
        show1: false,
        show2: false,
        show3: false,
        show4: false,
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-bottom-btn__inner{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    > div:last-child{

      @media(max-width: 680px){
        flex-direction: column;
      }

      > div {
        margin-left: 25px;

        @media(max-width: 680px){
          margin-bottom: 10px;
        }
      }
    }
  }

</style>
