<template>
  <TrackingPayPalTableAdmin
      :filterGetParams="filterGetParams"
      @filter="filter"
      @showMore="showMore"
      @resetFilter="resetFilter"
      @reload="reload"
      @changeFilter="changeFilter"
      @downloadFiles="downloadFiles"
  />
</template>

<script>
  import TrackingPayPalTableAdmin from "./TrackingPayPalTableAdmin/TrackingPayPalTableAdmin";
  import {queryFilterMixin} from "../../../../../mixins/routeFilterMixins/queryFilterMixin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "TrackingPayPalTable",
    components: {
      TrackingPayPalTableAdmin,
    },

    mixins: [queryFilterMixin, routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getTrackingForPayPalForPage,
        page: 1,
        onePage: 1,
        filterDate: [],

        filterGetParams: {},

      }
    },

    mounted() {
      if (!this.$route.query?.date) {
        this.$router.push({ path: '', query: {
            ...this.$route.query,
            'date': this.$moment(new Date()).subtract(1, 'months').format('MM/DD/YY')+ ','
                + this.$moment(new Date()).format('MM/DD/YY'),
          }})
      }

      this.filter()
    },

    methods: {
      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextTrackingForPayPalPage', true)

        // save filter params in store
        this.$store.commit('setTrackingForPayPalFilter', window.location.search)

        this.$store.dispatch('fetchTrackingForPayPal', url).then(() => {
          // skip get next page
          this.$store.commit('setNextTrackingForPayPalPage', false)
        })

      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
        }
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        console.log(222, this.filterDate);
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
              'between_created_at',
              date
          )
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      downloadFiles(type) {
        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage)

        let EXPORT_ACTION_NAME = 'getExportTrackingForPayPal'

        this.$store.dispatch(EXPORT_ACTION_NAME, {filter: url, exportType: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: `application/${type}`})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `Transactions.${type}`
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },
    }
  }
</script>

<style scoped>

</style>