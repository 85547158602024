<template>
  <div class="fragment">
    <TrackingPayPalHead/>

    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
            class="clear-992 height-search-engine"
            :backgroundImage="'balance'"
        >
          <template slot="body">
            <div class="tracking-pp">
              <div class="tracking-pp__title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['trackForPayPal_viewReport'])"></div>
                {{$t('trackForPayPal_viewReport.localization_value.value')}}
              </div>
              <div class="custom-row mt-4">
                <div class="custom-col">
                  <div class="custom-row">
                    <div class="custom-col">
                      <DatePickerDefault
                          class="date-picker-time-custom profit-report__dp"
                          v-model="date"
                      >
                        <template slot="body">
                          <date-picker
                              v-model="date"
                              ref="datePicker"
                              valueType="format"
                              :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                              :format="'MM/DD/YY'"
                              :placeholder="'mm/dd/yy'"
                              range
                          ></date-picker>
                        </template>
                      </DatePickerDefault>
                    </div>
                  </div>
                  <div class="custom-row">
                    <div class="custom-col mb-2"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['trackForPayPal_filter'])"></div>
                      <MainButton
                          :value="$t('trackForPayPal_filter.localization_value.value')"
                          @click.native="changeFilter"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <TrackingPayPalTable
            @showMore="$emit('showMore')"
            @resetFilter="$emit('resetFilter')"
            @reload="$emit('reload')"
            @downloadFiles="type => $emit('downloadFiles', type)"
        />
      </div>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import TrackingPayPalHead from "./TrackingPayPalHead/TrackingPayPalHead";
  import TrackingPayPalTable from "./TrackingPayPalTable/TrackingPayPalTable";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import {filterFunc} from "../../../../../../mixins/filterFuncMixin/filterFunc";

  export default {
    name: "TrackingPayPalTableAdmin",
    components: {
      MainButton,
      CardLeftBlock,
      TrackingPayPalHead,
      TrackingPayPalTable,
      DatePickerDefault,
      DatePicker,
    },

    mixins: [
      filterFunc
    ],

    props: {
      filterGetParams: Object,
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
      },
    },

    data(){
      return{
        options: [{}],
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        radioVal: 1,
        timeStart: '',
        timeEnd: '',
      }
    },

    methods: {
      changeRadio(val){
        this.radioVal = val
      },

      changeFilter() {
        let data = this.changeFilterParams(this, [
        ])
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../scss/mixins/mixins";
  @import "../../../../../../scss/colors";

  .tracking-pp{
    max-width: 400px;
    width: 100%;

    &__title{
      color: $black;
      font-weight: bold;
      line-height: normal;
      font-size: 24px;

      @include for-992{
        font-size: 24px;
        margin-bottom: 20px;
        line-height: normal;
      }
    }

    &__dp{
      border-radius: 6px;
      background: white;
    }
  }


</style>
